import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCamera } from '@fortawesome/free-solid-svg-icons';
import CustomAlert from '../CustomAlertForSubmision'

const MyProfile = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const fileInputRef = useRef(null);
    // const [chooseImage, setChooseImage] = useState("");
    const userProfile = useSelector((state) => state.profile);
    const [valueChange, setValueChange] = useState(false);
    const [dataSaved, setDataSaved] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [values, setValues] = useState({
        username: '',
        email: '',
        mobile: '',
        dob: ''
    });

    // Handle form input change
    const handleChangeValues = (event) => {
        const { name, value } = event.target;

        setValues((prevValue) => ({
            ...prevValue,
            [name]: value
        }));

        // Set valueChange to true whenever input changes
        setValueChange(true);
        setDataSaved(false); // Reset the save state since data has changed
    };

    // // Function to handle file upload
    // const handleFileChange = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) {
    //         console.error('No file selected');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('file', file); // Use 'file' as the key for your backend.
    //     formData.append('token', profile.token);

    //     try {
    //         const response = await axios.post(`http://localhost:3000/uploadProfileImageforUser?id=${userProfile.userid}`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         // Check if the response is successful and set the chosen image
    //         if (response.status === 200) {
    //             setChooseImage(response.data.image); // Assuming the image URL is in response.data.image
    //         }
    //     } catch (error) {
    //         setErrorMessage('Error uploading image');
    //         setError('Error uploading image');
    //         setShowAlert(true);
    //     }
    // };

    // Handle clicking on the camera icon
    // const handleIconClick = () => {
    //     if (fileInputRef.current) {
    //         fileInputRef.current.click(); // Open file input dialog
    //     }
    // };

    const backendUrl = process.env.REACT_APP_ENVIRONMENT === 'production'? 'https://test.backend.zmqrcode.com' : 'http://localhost:3000';  


    // Fetch profile data
    useEffect(() => {
        const fetchUserProfileData = async () => {
            if (profile.token) {
                try {
                    const response = await axios.post(
                        `${backendUrl}/finduserProfile`, // Example endpoint
                        { token: profile.token },
                        { headers: { 'Content-Type': 'application/json' } }
                    );

                    if (response.status === 200) {
                        setValues({
                            
                            username: response.data.user.username || '',
                            email: response.data.user.email || '',
                            mobile: response.data.user.mobile || '',
                            dob: response.data.user.dob || '',
                        });
                        setLoading(false);
                    }
                } catch (err) {
                    setError(err.message);
                    setLoading(false);
                    setShowAlert(true);
                    setErrorMessage(err.message); // Capture and show error message
                }
            } else {
                setError("No token provided");
                setLoading(false);
            }
        };

        fetchUserProfileData();
    }, [profile.token]);

    // Save user profile data
    const handleSave = async () => {
        try {
            const userData = {
                userid: userProfile.userid,
                username: values.username,
                email: values.email,
                mobile: values.mobile,
                dob: values.dob,
            };

            const response = await axios.post(`${backendUrl}/savedUserProfilefor`, userData, {
                headers: {
                    'Content-Type': 'application/json', // Set content type to JSON
                },
            });
    
            if (response.status === 200) {
                setDataSaved(true); // Indicate data has been saved
                setValueChange(false); // Reset the valueChange state
            }
        } catch (error) {
            setShowAlert(true);
            setErrorMessage('Error saving user profile');
            if (error.response) {
                setErrorMessage(error.response.data.message.join(', ')); // Display specific error messages from the response
            } else {
                setErrorMessage('An unexpected error occurred.'); // Fallback for other errors
            }
            console.error('Error saving user profile:', error);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="p-4 border-2 border-transparent justify-center h-[max-fit]">
            {/* <div className="relative">
                <div className="flex justify-center items-center">
                    {userProfile.image !== '' ? (
                        <img
                            src={`http://localhost:3000/getUploadForUserByFileId/${chooseImage === '' ? userProfile.image : chooseImage}`}
                            alt="User Profile"
                            className="w-32 h-32 rounded-full"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faCamera}
                            className="h-[46px] w-[50px] p-4 bg-[#4bdede] rounded-full text-white border-2 border-white cursor-pointer"
                            onClick={handleIconClick}
                        />
                    )}
                    <input
                        type="file"
                        accept="image/*"  // Restrict file types to images only
                        className="absolute inset-0 opacity-0 cursor-pointer"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </div>
            </div> */}
            <h2 className="flex items-center justify-center font-raleway text-300 m-4 font-bold">{`Hello ${profile.username},`}</h2>

            <div className="flex flex-col justify-start p-2 m-2 border-2 border-[#f0ebeb]">
                <h2 className="flex flex-start font-raleway text-300 m-4 font-bold">Profile</h2>
                <div className="flex flex-col justify-center">
                    <label className="font-raleway text-300 m-4 font-bold flex flex-start">Name</label>
                    <input
                        type="text"
                        className="border-2 border-[#f0ebeb] rounded-lg p-4"
                        name="username"
                        value={values.username || ""}
                        onChange={handleChangeValues}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <label className="font-raleway text-300 m-4 font-bold flex flex-start">Email</label>
                    <input
                        type="text"
                        className="border-2 border-[#f0ebeb] rounded-lg p-4"
                        name="email"
                        value={values.email || ""}
                        placeholder="Enter your email address"
                        onChange={handleChangeValues}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <label className="font-raleway text-300 m-4 font-bold flex flex-start">Mobile Number</label>
                    <input
                        type="tel"
                        className="border-2 border-[#f0ebeb] rounded-lg p-4"
                        name="mobile"
                        value={values.mobile || ""}
                        placeholder="Enter your phone number"
                        onChange={handleChangeValues}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <label className="font-raleway text-300 m-4 font-bold flex flex-start">Date of Birth</label>
                    <input
                        type="date"
                        className="border-2 border-[#f0ebeb] rounded-lg p-4"
                        name="dob"
                        value={values.dob || ""}
                        onChange={handleChangeValues}
                    />
                </div>
                <button
                    onClick={handleSave}
                    className="bg-white text-customBlue hover:bg-customBlue hover:text-white font-bold py-2 px-4 rounded flex flex-start w-[65px] m-[16px]"
                    disabled={!valueChange} // Disable button if no changes
                >
                    {dataSaved ? "Saved" : "Save"}
                </button>
            </div>
            {showAlert && <CustomAlert
                message={errorMessage}
                onClose={() => setShowAlert(false)}
            />}
        </div>
    );
};

export default MyProfile;
